import { AuthType, useAuthContext } from 'providers/login-provider'
import { useMemo } from 'react'

const permissions = {
  cavViewNotes: 'can-view-notes',
  canViewWarehouse: 'can-view-warehouse',
  canManageAnalyzes: 'can-manage-analyzes',
  canManageDoctors: 'can-manage-doctors',
  canViewSales: 'can-view-sales',
  canViewHistory: 'can-view-history',
  canViewStatistics: 'can-view-statistics',
  canViewDeletedPatientHistory: 'can-view-deleted-patient-history',
  canViewEditPasswords: 'can-view-edit-passwords',
} as const

export type PermissionKey = keyof typeof permissions

export type Permissions = Record<PermissionKey, boolean>

export const usePermissions = () => {
  const { auth } = useAuthContext()

  const isAuthenticated =
    auth.type === AuthType.NULL || auth.type === AuthType.UNAUTHENTICATED

  return useMemo(
    () =>
      Object.entries(permissions).reduce(
        (result, [key, permissions]) => ({
          ...result,
          [key]: !isAuthenticated
            ? auth.user.permissions
              ? auth.user.permissions.includes(permissions)
              : false
            : false,
        }),
        {} as Permissions,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth],
  )
}
