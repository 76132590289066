import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import { routes } from 'app/paths'
import { usePermissions } from 'lib/permissions'
import { AuthType, useAuthContext } from 'providers/login-provider'
import { MouseEvent, useState } from 'react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import Logo from '../global-cmp/images/vivet-logo.png'

const styles = {
  appBar: {
    border: 'none',
    mb: 5,
    backgroundColor: '#fff',
    borderBottom: '1px solid lightgray',
  },
  Logo: {
    width: 70,
    height: 60,
    objectFit: 'cover' as any,
    marginRight: 5,
  },
  linkItems: (link: string) => ({
    color: window.location.pathname.includes(link) ? '#0288d1' : '#000',
    cursor: 'pointer',
    fontFamily: 'MarkGEOCaps',
    py: 4,
    borderBottom: window.location.pathname.includes(link)
      ? '4px solid #0288d1'
      : '4px solid transparent',
    fontWeight: window.location.pathname.includes(link) ? 600 : 500,
    '&:hover': {},
  }),
}

export const AppHeader = () => {
  const {
    canManageAnalyzes,
    canManageDoctors,
    canViewWarehouse,
    cavViewNotes,
    canViewSales,
    canViewHistory,
    canViewStatistics,
    canViewEditPasswords,
    canViewDeletedPatientHistory,
  } = usePermissions()
  const { auth, removeToken } = useAuthContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleCloseNavMenu = (route: string) => {
    setAnchorElNav(null)
    navigate(route)
  }

  const pages = [
    {
      title: 'ჩანიშვნები',
      route: routes.notes,
      canView: cavViewNotes,
    },
    {
      title: 'საწყობი',
      route: routes.warehouse,
      canView: canViewWarehouse,
    },
    {
      title: 'ანალიზების მართვა',
      route: routes.super,
      canView: canManageAnalyzes,
    },
    {
      title: 'ექიმების მართვა',
      route: routes.doctorsList,
      canView: canManageDoctors,
    },
    {
      title: 'ფასდაკლებები',
      route: routes.sales,
      canView: canViewSales,
    },
    {
      title: 'ისტორია',
      route: routes.patientList,
      canView: canViewHistory,
    },
    {
      title: 'სტატისტიკა',
      route: routes.Statistic,
      canView: canViewStatistics,
    },
    {
      title: 'პაროლების რედაქტირება',
      route: routes.editPasswords,
      canView: canViewEditPasswords,
    },
    {
      title: 'წაშლილი პაციენტები',
      route: routes.deletedPatientList,
      canView: canViewDeletedPatientHistory,
    },
  ]
  const location = window.location.pathname

  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          ...styles.appBar,
          '@media print': {
            display: 'none',
          },
        }}
      >
        <Toolbar>
          <Stack
            direction="row"
            justifyContent="start"
            spacing={2}
            alignItems="center"
            sx={{
              flexGrow: 1,
            }}
          >
            <img src={Logo} alt="West Georgia" style={styles.Logo} />
          </Stack>

          {auth.type === AuthType.AUTHENTICATED && (
            <>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon color="primary" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map(
                    (page) =>
                      page.canView && (
                        <MenuItem
                          key={page.title}
                          onClick={() => handleCloseNavMenu(page.route)}
                        >
                          <Typography textAlign="center">
                            {page.title}
                          </Typography>
                        </MenuItem>
                      ),
                  )}
                </Menu>
              </Box>
              {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography> */}
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                {pages.map(
                  (page) =>
                    page.canView && (
                      <Button
                        key={page.title}
                        variant={location === page.route ? 'outlined' : 'text'}
                        onClick={() => handleCloseNavMenu(page.route)}
                        sx={{ m: 1, display: 'block' }}
                      >
                        {page.title}
                      </Button>
                    ),
                )}
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="end"
                  alignItems="center"
                >
                  <Typography variant="body1" sx={{ color: '#000' }}>
                    {auth.user.name}
                  </Typography>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="primary"
                    onClick={handleMenu}
                  >
                    <AccountCircle />
                  </IconButton>
                </Stack>

                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null)
                      removeToken()
                    }}
                  >
                    გასვლა
                  </MenuItem>
                </Menu>
              </Stack>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}
