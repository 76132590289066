export const authRoutes = {
  warehouse: '/super/warehouse/list',

  super: '/super',
  categoryView: '/super/:categoryId',
  doctorsList: '/super/doctor/list',
  notes: '/super/note/list',
  sales: '/super/sales/list',
  patientList: '/super/patient/history',
  deletedPatientList: '/patient/deletedHistory',
  patientHistory: '/super/patient/history/:patientId',
  administrator: '/administrator',
  Statistic: '/statistic',
  editPasswords: '/edit/passwords',

  laboratory: '/laboratory',
  laboratoryView: '/laboratory/:patientId',

  doctor: '/doctor',
  doctorView: '/doctor/:patientId',
}

export const unauthRoutes = {
  login: '/login',
}

export const routes = {
  ...authRoutes,
  ...unauthRoutes,
}
