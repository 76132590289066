import { PermissionKey } from 'lib/permissions'
import { ComponentType } from 'react'
import lazy, { PreloadableComponent } from 'react-lazy-with-preload'

import { routes } from './paths'

export type LazyRouteProps = {
  title: string
  path: string
  component: PreloadableComponent<ComponentType<any>>
  exact?: boolean
}

export type Route = LazyRouteProps & { permissions?: Array<PermissionKey> }

export const authRoutesData: Array<Route> = [
  // Super
  {
    title: 'Laboratory Patient Delete',
    path: routes.deletedPatientList,
    component: lazy(() => import('pages/super/laboratory/deletedPatient')),
    permissions: ['canViewDeletedPatientHistory'],
  },
  {
    title: 'Sales',
    path: routes.sales,
    component: lazy(() => import('pages/super/sales/list')),
    permissions: ['canViewSales'],
  },
  {
    title: 'Edit passwords',
    path: routes.editPasswords,
    component: lazy(() => import('pages/super/editPasswords')),
    permissions: ['canViewEditPasswords'],
  },
  {
    title: 'Notes',
    path: routes.notes,
    component: lazy(() => import('pages/super/note/list')),
    permissions: ['cavViewNotes'],
  },
  {
    title: 'Warehouse',
    path: routes.warehouse,
    component: lazy(() => import('pages/super/warehouse/list')),
    permissions: ['canViewWarehouse'],
  },
  {
    title: 'Super',
    path: routes.super,
    component: lazy(() => import('pages/super')),
    permissions: ['canManageAnalyzes'],
  },
  {
    title: 'Doctor list',
    path: routes.doctorsList,
    component: lazy(() => import('pages/super/doctor/list')),
    permissions: ['canManageDoctors'],
  },
  {
    title: 'Patient list',
    path: routes.patientList,
    component: lazy(() => import('pages/super/patient/list')),
  },
  {
    title: 'Statistic',
    path: routes.Statistic,
    component: lazy(() => import('pages/super/statistic')),
  },
  {
    title: 'Patient history',
    path: routes.patientHistory,
    component: lazy(() => import('pages/super/patient/history/[patientId]')),
  },

  // Category
  {
    title: 'Category',
    path: routes.categoryView,
    component: lazy(() => import('pages/super/[categoryId]')),
  },

  //Administrator
  {
    title: 'Administrator',
    path: routes.administrator,
    component: lazy(() => import('pages/administrator')),
  },

  //Laboratory
  {
    title: 'Laboratory',
    path: routes.laboratory,
    component: lazy(() => import('pages/laboratory')),
  },
  {
    title: 'Laboratory',
    path: routes.laboratoryView,
    component: lazy(() => import('pages/laboratory/[patientId]')),
  },

  //Doctor
  {
    title: 'Doctor',
    path: routes.doctor,
    component: lazy(() => import('pages/doctor')),
  },
  {
    title: 'Doctor',
    path: routes.doctorView,
    component: lazy(() => import('pages/doctor/[patientId]')),
  },
]

export const unauthRoutesData: Array<Route> = [
  {
    title: 'Authorization',
    path: routes.login,
    component: lazy(() => import('pages/login')),
  },
]

export const allRoutesData = [...authRoutesData, ...unauthRoutesData]
