import { Stack, Typography } from '@mui/material'

export const AppFooter = () => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
        sx={{ opacity: 0.3, display: 'none' }}
        my={10}
      >
        <Typography fontSize={18} sx={{ letterSpacing: 10 }}>
          tribus
        </Typography>
        <Typography fontSize={18} sx={{ letterSpacing: 2 }}>
          inc.
        </Typography>
      </Stack>
    </>
  )
}
