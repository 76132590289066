import constate from 'constate'
import { deleteAllCookies } from 'cookies'
import jwtDecode from 'jwt-decode'
import { refresh } from 'modules/login/login.api'
import { Auth as AuthResponse } from 'modules/login/login.codec'
import { useCallback, useEffect, useState } from 'react'

export enum AuthType {
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated',
  NULL = 'null',
}

export type UserState = {
  role: 'administrator'
  name: string
  permissions: string[]
}
export type AuthState =
  | { type: AuthType.NULL }
  | { type: AuthType.UNAUTHENTICATED }
  | {
      type: AuthType.AUTHENTICATED
      userId: string
      user: UserState
    }

export type JwtPayload = {
  userId: string
  user: UserState
}

export let GlobalAccessToken: string | null

export const decodeJwt = (accessToken: string) =>
  jwtDecode<JwtPayload>(accessToken)

export const Auth = () => {
  const [auth, setAuth] = useState<AuthState>({ type: AuthType.NULL })

  const setAuthData = useCallback(
    ({ ...args }: AuthResponse) => {
      GlobalAccessToken = args.access_token
      document.cookie = `rt=${args.refresh_token};path=/`

      const { userId, user } = decodeJwt(args.access_token)
      setAuth(() => ({
        type: AuthType.AUTHENTICATED,
        user,
        userId,
      }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const removeToken = useCallback(() => {
    setAuth({ type: AuthType.UNAUTHENTICATED })
    deleteAllCookies()
  }, [])

  const refreshToken = useCallback(
    async () => {
      try {
        const { ...args } = await refresh()
        setAuthData({ ...args })
      } catch (err) {
        removeToken()
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAuthData, removeToken],
  )

  useEffect(() => {
    refreshToken()
  }, [refreshToken])

  const watchToken = (event: StorageEvent) => {
    if (event.key === 'rt') {
      removeToken()
      setAuth({ type: AuthType.UNAUTHENTICATED })
    }
  }

  useEffect(() => {
    window.addEventListener('storage', watchToken)

    return () => {
      window.removeEventListener('storage', watchToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    auth,
    removeToken,
    setAuthData,
  } as const
}

export const [CheckLoginProvider, useAuthContext] = constate(Auth)
