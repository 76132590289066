/* eslint-disable react-hooks/rules-of-hooks */
import { isLeft } from 'fp-ts/lib/Either'
import { Mixed, TypeOf } from 'io-ts'
import toast from 'react-hot-toast'

import { useRequestData } from './use-request-data'
import { useRequestUrl } from './use-request-url'

export type TRequestMethod = 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH'
export type TRequestType = 'FORM' | 'JSON'
export type TRequestBody = Record<string | number, any> | BodyInit | null

export const request =
  (
    reqUrl: string,
    reqMethod: TRequestMethod,

    reqBody?: TRequestBody,
    reqType?: TRequestType,
  ) =>
  async <T extends Mixed>(codec: T) => {
    const requestBody = useRequestData({
      reqBody,
      reqMethod,
      reqType,
    })
    const requestUrl = useRequestUrl({ reqUrl })

    toast.loading('იტვირთება ...')

    const response = await fetch(requestUrl, requestBody)

    if (response.ok) {
      const data = await response.json()
      data.message && toast.success(data.message)

      if (isLeft(codec.decode(data))) toast.error('Error to decode')

      return data as TypeOf<typeof codec>
    } else {
      const error = (await response.json()) as TypeOf<typeof codec>
      error.message && toast.error(error.message)
      return Promise.reject({
        ...error,
      }) as unknown as TypeOf<typeof codec>
    }
  }
